const reCaptcha = {
    siteKey: process.env.GATSBY_RECAPTCHA_SITE_KEY,
};

const MONITORING = {
    RUM: {
        APPLICATION_ID: process.env.GATSBY_DATADOG_RUM_APPLICATION_ID,
        CLIENT_TOKEN: process.env.GATSBY_DATADOG_RUM_CLIENT_TOKEN,
    },
};

const APPLICATION = {
    VERSION: process.env.GATSBY_APP_VERSION,
    ENV: process.env.GATSBY_APP_ENV,
};

window.DATADOG_RUM_ENABLED = process.env.GATSBY_DATADOG_RUM_ENABLED;

const variables = { reCaptcha, MONITORING, APPLICATION };

export default variables;

export { reCaptcha, MONITORING, APPLICATION };
